import check from "assets/check.png"
import logo from "assets/logo.png"
import phone from "assets/phone.png"
import validate from "assets/validate.png"
import qrCodeIos from "assets/qrCodeIos.png"
import labelIos from "assets/labelIos.png"
import qrCodeAnroid from "assets/qrCodeAndroid.png"
import labelAndroid from "assets/labelAndroid.png"
import cupHolding from "assets/cupHolding.png"

const images = {
  check,
  logo,
  phone,
  validate,
  qrCodeIos,
  labelIos,
  qrCodeAnroid,
  labelAndroid,
  cupHolding
}

export default images
