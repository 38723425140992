import i18n from "i18next";
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            "choiceOfOffer": "CHOICE OF OFFER",
            "standard": "STANDARD",
            "visibility": "VISIBILITY",
            "monthlySubscriptionServiceStandard": "MONTHLY SUBSCRIPTION PROVIDING SHOOP CITY SERVICES <br/> 149€ HT.",
            "monthlySubscriptionServiceVisibility": "MONTHLY SUBSCRIPTION PROVIDING SHOOP CITY SERVICES <br/> 75€ HT.",
            "checkBox1": "By checking this box, I have read and accept the {{link1}}, {{link2}} and SHOOP CITY {{link3}} as well as the {{link4}} and {{link5}}",
            "checkBox2": "I have a promotional code",
            "validateMyChoice": "VALIDATE MY CHOICE AND CREATE MY ACCOUNT",
            "monthlySubscriptionShop": "MONTHLY SUBSCRIPTION PROVIDING SHOOP CITY SERVICES",
            "benefit": "Your benefit from <span> the offer of the community <br/> of communes of the upper Val d'Oise! </span> <br/> <br/>" +
                "<span> the monthly subscription to shoop city pro services </span> is therefore <br/>" +
                "<span> free for you </span> from" +
                "<span> 07/01/2021 </span> at <br/>" +
                "<span> 06/30/2023 </span>",
            "benefit2": "You get a special offer lasting 2 months! <br/> <br/> " +
                "<span> the monthly subscription to shoop city pro services </span> is therefore <br/>" +
                "<span> free for you </span> from" +
                "<span> 07/01/2021 </span> at <br/>" +
                "<span> 06/30/2023 </span>",
            "period": "Beyond this period, the monthly price for SHOOP CITY services will be 25€ <strong>HT</strong> / shop (<strong>offer without commitment</strong> according to the CGUPSC and CGFS SHOOP CITY)",
            "period2": "Beyond this period, the monthly price for SHOOP CITY services will be 50€ <strong>HT</strong> / shop (<strong>offer without commitment</strong> according to the CGUPSC and CGFS SHOOP CITY)",
            "promotionOffer": "PROMOTIONAL OFFER",
            "bankCharge": "Bank charges in addition to the subscription: <br/> 1.8% of the purchase amount including VAT + 0.18 € per transaction",
            "congratulation": "CONGRATULATION!",
            "society": "SOCIETY",
            "REPRESENTED BY": "REPRESENTED BY",
            "createMyShop": "Create my shop",
            "thankYou": "THANKS <br/> FOR YOUR SUBSCRIBE!",
            "enterOtpCode": "Please enter the received sms code",
            "smsSendTo": "SMS send to",
            "codeNotReceived": "<p>Code not received? <span>Resend me an SMS code</span></p>",
            "numberError": "<p>Number error? <span>Change my number</span></p>",
            "validate": "validate",
            "companyName": "Company name",
            "shopName": "Shop name",
            "email": "Email address",
            "createPassword": "Create a password",
            "confirmPassword": "Confirm password",
            "countryNumberCode": "Country code",
            "phoneNumber": "Phone number",
            "channel": "N°, Channel",
            "codePostal": "Code Postal",
            "city": "City, Country",
            "lastName": "Last name",
            "firstName": "First name",
            "continue": "Continue",
            "mainSector": "WHAT IS YOUR MAIN SECTOR OF ACTIVITY?",
            "checkTheBox": "Check the corresponding box",
            "Invalid email": "Invalid email",
            "Invalid IBAN": "Invalid IBAN",
            "bankInformation": "BANKING INFORMATION",
            "cardHolderName": "Cardholder name",
            "iban": "IBAN",
            "bic": "BIC",
            "Invalid BIC": "Invalid BIC",
            "Required": "Required",
            "changeNumber": "Are you SURE you want to modify the number of the manager?",
            "cancel": "Cancel",
            "validateEmail": "Please validate your email before continue!",
            "emailExisted": "Email already existed!",
            "fileToBig": "File is too big!",
            "invalidPromoCode": "Invalid Promo code!",
            "confirmEmail": "CONFIRM EMAIL ADDRESS",
            "resetPrizePool": "We are happy to inform you that the prize pool has been successfully reset on the client side.",
            "handOverLot": "This means you can hand over the lot to the customer.",
            "sendEmailTo": "We have sent an email to {{userMail}} to verify that you are the owner.",
            "checkMailBox": "Check your inbox and follow the instructions to finalize your SHOOP CITY account setup",
            "ARTISAN": 'Artisan',
            "PROFESSION": 'Liberal Profession',
            "AUTO": 'Auto-entrepreneur',
            "COMMERCIAL": 'Commercial society (SA, SAS, SASU, SARL, EURL)',
            "legalStatus": "Legal Status",
            "matchPassword": "Passwords must match",
            "passwordLength": "password length: 6-20 characters!",
            "tooShort": "Too short!",
            "uploadYourPhoto": "Please choose a photo before continue!",
            "callThisNumber": "Please call this number if you need help!",
            "tradeNameRequired": "Trade name or Company name must min 3 and max 50 characters and not include special characters",
            "firstNameRequired": "First name must min 3 and max 50 characters and not include special characters",
            "lastNameRequired": "Last name must min 3 and max 50 characters and not include special characters",
            "phoneNumberLength": "Phone number must be at least 10 characters",
            "Invalid phone number": "Invalid phone number",
            "invalidPhoneNumber": "Invalid phone number",
            "invalidCountryNumberCode": "Invalid country number code",
            "invalidBank": "The bank account is invalid",
            "emailNotVerify": "Please verify your email before continue!",
            "privacy": "privacy policy",
            "sellingProduct": "Selling products",
            "userDeleted": "User has been deleted. Please register again!",
            "invalidHereId": "Please choose our suggestion for address!",
            "registrationTimeOut": "Your registration is out of time, please try again!",
            "Forbidden": "Interdite",
            "fullFilledCode": "Please fullfilled verification code!",
            "fillField": "Please fill in all fields",
            "download": "Quickly download the SHOOP CITY PRO application",
            "qrCode": "Here is the QR code that leads to the SHOOP CITY merchant / professional app",
            "returnToHome": "Return to home",
            "services": "Services"
        }
    },
    fr: {
        translation: {
            "choiceOfOffer": "CHOIX DE L’OFFRE",
            "standard": "STANDARD",
            "visibility": "VISIBILITÉ",
            "monthlySubscriptionServiceStandard": "ABONNEMENT MENSUEL FOURNITURE DE SERVICES SHOOP CITY <br/> 149 € HT",
            "monthlySubscriptionServiceVisibility": "ABONNEMENT MENSUEL FOURNITURE DE SERVICES SHOOP CITY <br/> 75 € HT",
            "checkBox1": "En cochant cette case, j'ai lu et j’accepte les {{link1}}, {{link2}} et {{link3}} de SHOOP CITY ainsi que les {{link4}} et {{link5}}",
            "checkBox2": "J’ai un code promotionnel",
            "validateMyChoice": "VALIDER MON CHOIX ET CRÉER MON COMPTE",
            "monthlySubscriptionShop": "ABONNEMENT MENSUEL FOURNITURE DE SERVICES SHOOP CITY",
            "benefit": " Vous bénéficiez de <span>l’offre de la communauté <br/> de communes du haut val d’Oise !</span> <br/><br/>" +
                "<span>l’abonnement mensuel aux services shoop city pro</span> est donc <br/>" +
                "<span>gratuit pour vous</span> du " +
                "<span>01/07/2021</span> au <br/>" +
                "<span>30/06/2023</span>",
            "benefit2": "Vous bénéficiez d'une offre spéciale d'une durée de 2 mois !  <br/><br/>" +
                "<span>l’abonnement mensuel aux services shoop city pro</span> est donc <br/>" +
                "<span>gratuit pour vous</span> jusqu'au " +
                "<span>30/04/2022</span>",
            "period": "Au delà de cette période, la tarification mensuelle aux services SHOOP CITY sera de 25€ <strong>HT</strong> / boutique (<strong>offre sans engagement</strong> suivant les CGUPSC et CGFS SHOOP CITY)",
            "period2": "Au delà de cette période, la tarification mensuelle aux services SHOOP CITY sera de 50€ <strong>HT</strong> / boutique (<strong>offre sans engagement</strong> suivant les CGUPSC et CGFS SHOOP CITY)",
            "promotionOffer": "OFFRE PROMOTIONNELLE",
            "bankCharge": "Frais bancaires en sus de l'abonnement: <br/> 1,8% du montant TTC de l'achat + 0.18€ par transaction",
            "congratulation": "FÉLICITATIONS!",
            "society": "SOCIÉTÉ",
            "REPRESENTED BY": "REPRÉSENTÉ PAR",
            "createMyShop": "Créer ma boutique",
            "thankYou": "MERCI <br /> POUR VOTRE INSCRIPTION !",
            "enterOtpCode": "Veuillez rentrer le code sms reçu",
            "smsSendTo": "SMS envoyer au",
            "codeNotReceived": "<p>Code non reçu ? <span>Renvoyez-moi un code SMS</span></p>",
            "numberError": "<p>Erreur de numéro ? <span>Modifier mon numéro</span></p>",
            "validate": "valider",
            "companyName": "Nom de la compagnie",
            "shopName": "Nom de la boutique",
            "email": "Adresse e-mail",
            "createPassword": "Créer un mot de passe",
            "confirmPassword": "Confirmer le mot de passe",
            "countryNumberCode": "Code pays",
            "phoneNumber": "Numéro téléphone mobile du gérant",
            "phoneNumberStore": "Numéro de téléphone de la boutique",
            "channel": "N°, Voie",
            "codePostal": "Code Postal",
            "city": "Ville, Pays",
            "lastName": "Nom",
            "firstName": "Prénom",
            "continue": "Continuer",
            "mainSector": "QUEL EST VOTRE PRINCIPAL SECTEUR D’ACTIVITÉ ?",
            "checkTheBox": "Cochez la case correspondante",
            "Required": "Obligatoire",
            "Invalid email": "Email invalide",
            "bankInformation": "INFORMATIONS BANCAIRES",
            "cardHolderName": "Nom du titulaire",
            "iban": "IBAN",
            "bic": "BIC",
            "Invalid IBAN": "IBAN invalide",
            "Invalid BIC": "BIC invalide",
            "changeNumber": "Etes vous CERTAIN de vouloir modifier le numero du gerant ?",
            "cancel": "Annuler",
            "validateEmail": "Merci de valider votre email avant de continuer !",
            "emailExisted": "L'email existait déjà !",
            "fileToBig": "Le fichier est trop gros !",
            "invalidPromoCode": "Code promotionnel invalide !",
            "confirmEmail": "CONFIRMER L’ADRESSE MAIL",
            "resetPrizePool": "Nous sommes heureux de vous informer que la cagnotte a été réinitialisée avec succès du côté client.",
            "handOverLot": "Cela signifie que vous pouvez remettre le lot au client",
            "sendEmailTo": "Nous avons envoyé un email à l’adresse {{userMail}} pour vérifier que vous en êtes le titulaire.",
            "checkMailBox": "Consultez votre boîte de réception et suivez les instructions pour finaliser la configuration de votre compte SHOOP CITY",
            "ARTISAN": 'Artisan',
            "PROFESSION": 'Profession Libérale',
            "AUTO": 'Auto-entrepreneur',
            "COMMERCIAL": 'Société commerciale (SA, SAS, SASU, SARL, EURL)',
            "legalStatus": "Forme juridique",
            "matchPassword": "Les mots de passe doivent correspondre",
            "passwordLength": "longueur du mot de passe : 6-20 caractères !",
            "tooShort": "Trop court!",
            "uploadYourPhoto": "Veuillez choisir une photo avant de continuer !",
            "callThisNumber": "Veuillez appeler ce numéro si vous avez besoin d'aide!",
            "tradeNameRequired": "Le nom commercial ou le nom de l'entreprise doit contenir au minimum 3 et au maximum 50 caractères et ne pas inclure de caractères spéciaux",
            "firstNameRequired": "Le prénom doit contenir au minimum 3 et maximum 50 caractères et ne pas inclure de caractères spéciaux",
            "lastNameRequired": "Le nom de famille doit contenir au minimum 3 et au maximum 50 caractères et ne pas inclure de caractères spéciaux",
            "phoneNumberLength": "Le numéro de téléphone doit comporter au moins 10 caractères",
            "invalidPhoneNumber": "Numéro de téléphone invalide",
            "invalidCountryNumberCode": "Code pays invalide",
            "Invalid phone number": "Numéro de téléphone invalide",
            "invalidBank": "Le compte bancaire est invalide",
            "emailNotVerify": "Veuillez vérifier votre e-mail avant de continuer!",
            "privacy": "politique de confidentialité",
            "sellingProduct": "Vente de produits",
            "userDeleted": "L'utilisateur a été supprimé. Veuillez vous inscrire à nouveau!",
            "invalidHereId": "Veuillez choisir notre suggestion d'adresse!",
            "registrationTimeOut": "Votre inscription est dépassée, veuillez réessayer!",
            "Forbidden": "Interdite",
            "fullFilledCode": "Veuillez remplir le code de vérification!",
            "fillField": "Merci de remplir tous les champs",
            "download": "Téléchargez vite l'application SHOOP CITY PRO",
            "qrCode": "Voici le QR code qui mène à l'application SHOOP CITY commerçants/professionnels",
            "returnToHome": "Revenir à l'accueil",
            "services": "Prestations de services"
        }
    }
};


i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'fr',
    resources,
    // lng: process.env.REACT_APP_STAGE === "production" ? 'fr' : 'fr',
    lng: 'fr',
    debug: false,
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    }
})

export default i18n
