import { AxiosResponse } from 'axios';
import { baseApi } from '../base/base.api';

export const LOYALTY_API = {
    GIFT: '/v1/loyalty/buyer/gift',
}

export const claimGift = (body: {loyaltyProgramId: string | null, buyerId: string | null}): Promise<AxiosResponse<any>> => {
    return baseApi.post<any>(LOYALTY_API.GIFT, body)
}
