import { baseApi } from "../../api/base/base.api"
import { MERCHANTS_URL } from "../../api/merchant/merchant.api"

// Types
import { Phone } from "../../types"

export const checkEmailExistedRequest = (email: string) => {
  return (dispatch: any) => {
    dispatch({
      type: "CHECK_EMAIL_EXISTED_REQUEST",
    })
    baseApi
      .post(MERCHANTS_URL.CHECK_EMAIL, { email })
      .then((result: any) => {
        dispatch({
          type: "CHECK_EMAIL_EXISTED_SUCCESS",
          payload: {
            data: result.data.isExisted,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "CHECK_EMAIL_EXISTED_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const signUpRequest = (info: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "SIGN_UP_REQUEST",
    })
    baseApi
      .post(MERCHANTS_URL.SIGN_UP, { ...info, version: 2 })
      .then((result: any) => {
        dispatch({
          type: "SIGN_UP_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "SIGN_UP_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const checkUserMolliePromotedRequest = (phones: Phone[]) => {
  return (dispatch: any) => {
    dispatch({
      type: "CHECK_USER_MOLLIE_PROMOTED_REQUEST",
    })
    baseApi
      .post(MERCHANTS_URL.CHECK_USER_MOLLIE_PROMOTED, { phones })
      .then((result: any) => {
        dispatch({
          type: "CHECK_USER_MOLLIE_PROMOTED_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "CHECK_USER_MOLLIE_PROMOTED_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const updateMerchantContactRequest = (contactInfo: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_MERCHANT_CONTACT_REQUEST",
    })
    baseApi
      .put(MERCHANTS_URL.UPDATE_MERCHANT, contactInfo)
      .then((result: any) => {
        dispatch({
          type: "UPDATE_MERCHANT_CONTACT_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "UPDATE_MERCHANT_CONTACT_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const uploadIbanKycRequest = (file: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPLOAD_IBAN_KYC_REQUEST",
    })
    baseApi
      .post(MERCHANTS_URL.UPLOAD_IBAN_KYC, file)
      .then((result: any) => {
        dispatch({
          type: "UPLOAD_IBAN_KYC_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "UPLOAD_IBAN_KYC_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const createMollieBankRequest = (bankInfo: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "CREATE_MOLLIE_BANK_REQUEST",
    })
    baseApi
      .post(MERCHANTS_URL.CREATE_BANK, bankInfo)
      .then((result: any) => {
        dispatch({
          type: "CREATE_MOLLIE_BANK_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "CREATE_MOLLIE_BANK_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const updatePasswordRequest = (objData: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_PASSWORD_REQUEST",
    })
    baseApi
      .put(MERCHANTS_URL.CHANGE_PASSWORD, objData)
      .then((result: any) => {
        dispatch({
          type: "UPDATE_PASSWORD_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "UPDATE_PASSWORD_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const getMeRequest = () => {
  return (dispatch: any) => {
    dispatch({
      type: "GET_ME_REQUEST",
    })
    baseApi
      .get(MERCHANTS_URL.GET_ME)
      .then((result: any) => {
        dispatch({
          type: "GET_ME_SUCCESS",
          payload: {
            data: result.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: "GET_ME_FAILURE",
          error: error.response.data,
        })
      })
  }
}

export const saveKycDocument = (document: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "SAVE_KYC_DOCUMENT",
      document,
    })
  }
}
