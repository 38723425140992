import {
  signUpRequest,
  checkEmailExistedRequest,
  checkUserMolliePromotedRequest,
  updateMerchantContactRequest,
  uploadIbanKycRequest,
  createMollieBankRequest,
  updatePasswordRequest,
  getMeRequest,
  saveKycDocument,
} from "./MerchantAction"

export const MerchantAction = {
  signUpRequest,
  checkEmailExistedRequest,
  checkUserMolliePromotedRequest,
  updateMerchantContactRequest,
  createMollieBankRequest,
  uploadIbanKycRequest,
  getMeRequest,
  updatePasswordRequest,
  saveKycDocument,
}
