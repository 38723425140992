export const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    COUNTRY_NUMBER_CODE: /^[0-9]{1,4}$/,
    PHONE_NUMBER: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    ZIP_CODE: /^\d{5}(?:[-\s]\d{4})?$/,
    // IBAN: /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
    // BIC: /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?\z/i,
    BIC: /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/,
    IBAN: /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/,
    SHOP_NAME: /^[0-9a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\S*$]/,
    NAME: /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\S*$]/,
    NUMBER_ONLY: /^[0-9]*$/,
    OTP_PHONE_NUMBER: /(84|0[3|5|7|8|9|1|2|4|6])+([0-9]{8})\b/
}

export const PROMOTION = {}

export enum PROMOTION_CODE {
    PROMO2021 = 'PROMO2021',
    SHOOP2022 = 'SHOOP2022'
}


export const ERROR = {
    EMAIL_EXISTED: 'emailExisted',
    FILE_TO_BIG: 'fileToBig',
    INVALID_PROMO_CODE: 'invalidPromoCode',
    INVALID_PHONE_NUMBER: 'invalidPhoneNumber',
    EMAIL_NOT_VERIFY: 'emailNotVerify',
    INVALID_HERE_ID: 'invalidHereId',
    REGISTRATION_TIMEOUT: 'registrationTimeOut',
    FULL_FILLED_CODE: 'fullFilledCode'
}

export const TIME_OUT = {
    ONE_SECOND: 1000,
    THREE_SECOND: 3000,
    FIVE_SECOND: 5000
}

export const TYPE_ACCOUNT = {
    product: {
        title: 'sellingProduct',
        value: 'selling-products'
    },
    service: {
        title: 'services',
        value: 'service-provision'
    },
    public: {
        title: 'Public establishment',
        value: 'public-establishments'
    }
}

export const OTP_TIMEOUT = 60

export const STORAGE_KEY = {
    OTP: 'otp',
    REGISTER: 'register',
    ZIP_CODE_CITY: 'zipCodeCity',
    TYPE_ACCOUNT: 'typeAccount',
    TOKEN: 'token',
    PROMO_CODE: 'promoCode',
    PROMO_CODE_ID: 'promoCodeId',
    OTP_CODE: 'otpCode',
    BANK_IMG: 'bankImg',
    USER_DATA: 'userData',
    KYC_DOCUMENT: 'kycDocument',
    KYC_DOCUMENT_SHOW: 'kycDocumentShow',
    LANGUAGE: 'i18nextLng'

}


export const COUNTRY_CODE_PHONE = {
    BD: 880,
    BE: 32,
    BF: 226,
    BG: 359,
    BA: 387,
    WF: 681,
    BL: 590,
    BN: 673,
    BO: 591,
    BH: 973,
    BI: 257,
    BJ: 229,
    BT: 975,
    BW: 267,
    WS: 685,
    BQ: 599,
    BR: 55,
    BY: 375,
    BZ: 501,
    RW: 250,
    RS: 381,
    TL: 670,
    RE: 262,
    TM: 993,
    TJ: 992,
    RO: 40,
    TK: 690,
    GW: 245,
    GT: 502,
    GR: 30,
    GQ: 240,
    GP: 590,
    JP: 81,
    GY: 592,
    GF: 594,
    GE: 995,
    GB: 44,
    GA: 241,
    SV: 503,
    GN: 224,
    GM: 220,
    GL: 299,
    GI: 350,
    GH: 233,
    OM: 968,
    TN: 216,
    JO: 962,
    HR: 385,
    HT: 509,
    HU: 36,
    HK: 852,
    HN: 504,
    VE: 58,
    PS: 970,
    PW: 680,
    PT: 351,
    SJ: 47,
    PY: 595,
    IQ: 964,
    PA: 507,
    PF: 689,
    PG: 675,
    PE: 51,
    PK: 92,
    PH: 63,
    PN: 870,
    PL: 48,
    PM: 508,
    ZM: 260,
    EH: 212,
    EE: 372,
    EG: 20,
    ZA: 27,
    EC: 593,
    IT: 39,
    VN: 84,
    SB: 677,
    ET: 251,
    SO: 252,
    ZW: 263,
    SA: 966,
    ES: 34,
    ER: 291,
    ME: 382,
    MD: 373,
    MG: 261,
    MF: 590,
    MA: 212,
    MC: 377,
    UZ: 998,
    MM: 95,
    ML: 223,
    MO: 853,
    MN: 976,
    MH: 692,
    MK: 389,
    MU: 230,
    MT: 356,
    MW: 265,
    MV: 960,
    MQ: 596,
    MR: 222,
    UG: 256,
    TZ: 255,
    MY: 60,
    MX: 52,
    IL: 972,
    FR: 33,
    IO: 246,
    SH: 290,
    FI: 358,
    FJ: 679,
    FK: 500,
    FM: 691,
    FO: 298,
    NI: 505,
    NL: 31,
    NO: 47,
    NA: 264,
    VU: 678,
    NC: 687,
    NE: 227,
    NF: 672,
    NG: 234,
    NZ: 64,
    NP: 977,
    NR: 674,
    NU: 683,
    CK: 682,
    CI: 225,
    CH: 41,
    CO: 57,
    CN: 86,
    CM: 237,
    CL: 56,
    CC: 61,
    CA: 1,
    CG: 242,
    CF: 236,
    CD: 243,
    CZ: 420,
    CY: 357,
    CX: 61,
    CR: 506,
    CW: 599,
    CV: 238,
    CU: 53,
    SZ: 268,
    SY: 963,
    SX: 599,
    KG: 996,
    KE: 254,
    SS: 211,
    SR: 597,
    KI: 686,
    KH: 855,
    KM: 269,
    ST: 239,
    SK: 421,
    KR: 82,
    SI: 386,
    KP: 850,
    KW: 965,
    SN: 221,
    SM: 378,
    SL: 232,
    SC: 248,
    KZ: 7,
    SG: 65,
    SE: 46,
    SD: 249,
    DJ: 253,
    DK: 45,
    DE: 49,
    YE: 967,
    DZ: 213,
    US: 1,
    UY: 598,
    YT: 262,
    UM: 1,
    LB: 961,
    LA: 856,
    TV: 688,
    TW: 886,
    TR: 90,
    LK: 94,
    LI: 423,
    LV: 371,
    TO: 676,
    LT: 370,
    LU: 352,
    LR: 231,
    LS: 266,
    TH: 66,
    TG: 228,
    TD: 235,
    LY: 218,
    VA: 379,
    AE: 971,
    AD: 376,
    AF: 93,
    IS: 354,
    IR: 98,
    AM: 374,
    AL: 355,
    AO: 244,
    AR: 54,
    AU: 61,
    AT: 43,
    AW: 297,
    IN: 91,
    AZ: 994,
    IE: 353,
    ID: 62,
    UA: 380,
    QA: 974,
    MZ: 258
}

export const COUNTRY_CODE_ISO_MAPPING: any = {
    AFG: 'AF',
    ALA: 'AX',
    ALB: 'AL',
    DZA: 'DZ',
    ASM: 'AS',
    AND: 'AD',
    AGO: 'AO',
    AIA: 'AI',
    ATA: 'AQ',
    ATG: 'AG',
    ARG: 'AR',
    ARM: 'AM',
    ABW: 'AW',
    AUS: 'AU',
    AUT: 'AT',
    AZE: 'AZ',
    BHS: 'BS',
    BHR: 'BH',
    BGD: 'BD',
    BRB: 'BB',
    BLR: 'BY',
    BEL: 'BE',
    BLZ: 'BZ',
    BEN: 'BJ',
    BMU: 'BM',
    BTN: 'BT',
    BOL: 'BO',
    BIH: 'BA',
    BWA: 'BW',
    BVT: 'BV',
    BRA: 'BR',
    VGB: 'VG',
    IOT: 'IO',
    BRN: 'BN',
    BGR: 'BG',
    BFA: 'BF',
    BDI: 'BI',
    KHM: 'KH',
    CMR: 'CM',
    CAN: 'CA',
    CPV: 'CV',
    CYM: 'KY',
    CAF: 'CF',
    TCD: 'TD',
    CHL: 'CL',
    CHN: 'CN',
    HKG: 'HK',
    MAC: 'MO',
    CXR: 'CX',
    CCK: 'CC',
    COL: 'CO',
    COM: 'KM',
    COG: 'CG',
    COD: 'CD',
    COK: 'CK',
    CRI: 'CR',
    CIV: 'CI',
    HRV: 'HR',
    CUB: 'CU',
    CYP: 'CY',
    CZE: 'CZ',
    DNK: 'DK',
    DJI: 'DJ',
    DMA: 'DM',
    DOM: 'DO',
    ECU: 'EC',
    EGY: 'EG',
    SLV: 'SV',
    GNQ: 'GQ',
    ERI: 'ER',
    EST: 'EE',
    ETH: 'ET',
    FLK: 'FK',
    FRO: 'FO',
    FJI: 'FJ',
    FIN: 'FI',
    FRA: 'FR',
    GUF: 'GF',
    PYF: 'PF',
    ATF: 'TF',
    GAB: 'GA',
    GMB: 'GM',
    GEO: 'GE',
    DEU: 'DE',
    GHA: 'GH',
    GIB: 'GI',
    GRC: 'GR',
    GRL: 'GL',
    GRD: 'GD',
    GLP: 'GP',
    GUM: 'GU',
    GTM: 'GT',
    GGY: 'GG',
    GIN: 'GN',
    GNB: 'GW',
    GUY: 'GY',
    HTI: 'HT',
    HMD: 'HM',
    VAT: 'VA',
    HND: 'HN',
    HUN: 'HU',
    ISL: 'IS',
    IND: 'IN',
    IDN: 'ID',
    IRN: 'IR',
    IRQ: 'IQ',
    IRL: 'IE',
    IMN: 'IM',
    ISR: 'IL',
    ITA: 'IT',
    JAM: 'JM',
    JPN: 'JP',
    JEY: 'JE',
    JOR: 'JO',
    KAZ: 'KZ',
    KEN: 'KE',
    KIR: 'KI',
    PRK: 'KP',
    KOR: 'KR',
    KWT: 'KW',
    KGZ: 'KG',
    LAO: 'LA',
    LVA: 'LV',
    LBN: 'LB',
    LSO: 'LS',
    LBR: 'LR',
    LBY: 'LY',
    LIE: 'LI',
    LTU: 'LT',
    LUX: 'LU',
    MKD: 'MK',
    MDG: 'MG',
    MWI: 'MW',
    MYS: 'MY',
    MDV: 'MV',
    MLI: 'ML',
    MLT: 'MT',
    MHL: 'MH',
    MTQ: 'MQ',
    MRT: 'MR',
    MUS: 'MU',
    MYT: 'YT',
    MEX: 'MX',
    FSM: 'FM',
    MDA: 'MD',
    MCO: 'MC',
    MNG: 'MN',
    MNE: 'ME',
    MSR: 'MS',
    MAR: 'MA',
    MOZ: 'MZ',
    MMR: 'MM',
    NAM: 'NA',
    NRU: 'NR',
    NPL: 'NP',
    NLD: 'NL',
    ANT: 'AN',
    NCL: 'NC',
    NZL: 'NZ',
    NIC: 'NI',
    NER: 'NE',
    NGA: 'NG',
    NIU: 'NU',
    NFK: 'NF',
    MNP: 'MP',
    NOR: 'NO',
    OMN: 'OM',
    PAK: 'PK',
    PLW: 'PW',
    PSE: 'PS',
    PAN: 'PA',
    PNG: 'PG',
    PRY: 'PY',
    PER: 'PE',
    PHL: 'PH',
    PCN: 'PN',
    POL: 'PL',
    PRT: 'PT',
    PRI: 'PR',
    QAT: 'QA',
    REU: 'RE',
    ROU: 'RO',
    RUS: 'RU',
    RWA: 'RW',
    BLM: 'BL',
    SHN: 'SH',
    KNA: 'KN',
    LCA: 'LC',
    MAF: 'MF',
    SPM: 'PM',
    VCT: 'VC',
    WSM: 'WS',
    SMR: 'SM',
    STP: 'ST',
    SAU: 'SA',
    SEN: 'SN',
    SRB: 'RS',
    SYC: 'SC',
    SLE: 'SL',
    SGP: 'SG',
    SVK: 'SK',
    SVN: 'SI',
    SLB: 'SB',
    SOM: 'SO',
    ZAF: 'ZA',
    SGS: 'GS',
    SSD: 'SS',
    ESP: 'ES',
    LKA: 'LK',
    SDN: 'SD',
    SUR: 'SR',
    SJM: 'SJ',
    SWZ: 'SZ',
    SWE: 'SE',
    CHE: 'CH',
    SYR: 'SY',
    TWN: 'TW',
    TJK: 'TJ',
    TZA: 'TZ',
    THA: 'TH',
    TLS: 'TL',
    TGO: 'TG',
    TKL: 'TK',
    TON: 'TO',
    TTO: 'TT',
    TUN: 'TN',
    TUR: 'TR',
    TKM: 'TM',
    TCA: 'TC',
    TUV: 'TV',
    UGA: 'UG',
    UKR: 'UA',
    ARE: 'AE',
    GBR: 'GB',
    USA: 'US',
    UMI: 'UM',
    URY: 'UY',
    UZB: 'UZ',
    VUT: 'VU',
    VEN: 'VE',
    VNM: 'VN',
    VIR: 'VI',
    WLF: 'WF',
    ESH: 'EH',
    YEM: 'YE',
    ZMB: 'ZM',
    ZWE: 'ZW',
    XKX: 'XK'
}

export enum TYPE_KYC {
    BANK_RIB = 'bank-rib'
}

export enum OFFER_TYPE {
  STANDARD = 'standard',
  VISIBILITY = 'visibility',

}

export const LEGAL_STATUS = {
    ARTISAN: 'artisan',
    PROFESSION: 'liberal-profession',
    AUTO: 'auto-entrepreneur',
    COMMERCIAL: 'commercial-society'
}
