import Immutable from "seamless-immutable"

// Functions
import { getItemFromStorage, setStorage, setToken } from "../../common/storage"

// Constants
import { STORAGE_KEY } from "../../constant/constant"

const userDataStorage = getItemFromStorage(STORAGE_KEY.USER_DATA)
const userData = userDataStorage && JSON.parse(userDataStorage)

const kycDocumentShowStorage = getItemFromStorage(STORAGE_KEY.KYC_DOCUMENT_SHOW)
const kycDocumentShow =
  kycDocumentShowStorage && JSON.parse(kycDocumentShowStorage)

const kycDocument = getItemFromStorage(STORAGE_KEY.KYC_DOCUMENT)

const initalState = Immutable({
  isExistEmail: false,
  userData,
  mollie: null,

  kycDocumentShow,

  fetchingCheckEmailExisted: false,
  errorCheckEmailExisted: null,

  fetchingSignUp: false,
  errorSignUp: null,

  fetchingCheckUserMolliePromoted: false,
  errorCheckUserMolliePromoted: null,

  fetchingUpdateMerchantContact: false,
  errorUpdateMerchantContact: null,

  fetchingUploadIbanKyc: false,
  errorUploadIbanKyc: null,

  fetchingCreateMollieBank: false,
  errorCreateMollieBank: null,

  fetchingUpdatePassword: false,
  errorUpdatePassword: null,

  fetchingGetMe: false,
  errorGetMe: null,
  kycDocument,
})

const MerchantReducer = (state = initalState, action: any) => {
  switch (action.type) {
    // CHECK MAIL EXISTED
    case "CHECK_EMAIL_EXISTED_REQUEST": {
      return state.merge({
        fetchingCheckEmailExisted: true,
        errorCheckEmailExisted: null,
      })
    }
    case "CHECK_EMAIL_EXISTED_SUCCESS": {
      return state.merge({
        isExistEmail: action.payload.data,
        fetchingCheckEmailExisted: false,
        errorCheckEmailExisted: null,
      })
    }
    case "CHECK_EMAIL_EXISTED_FAILURE": {
      return state.merge({
        fetchingCheckEmailExisted: false,
        errorCheckEmailExisted: action.error,
      })
    }

    // SIGN UP
    case "SIGN_UP_REQUEST": {
      return state.merge({
        fetchingSignUp: true,
        errorSignUp: null,
      })
    }
    case "SIGN_UP_SUCCESS": {
      const userData = action.payload.data
      setToken(userData.accessToken)
      setStorage(STORAGE_KEY.USER_DATA, userData)
      return state.merge({
        userData,
        fetchingSignUp: false,
        errorSignUp: null,
      })
    }
    case "SIGN_UP_FAILURE": {
      return state.merge({ fetchingSignUp: false, errorSignUp: action.error })
    }

    // CHECK USER MOLLIE PROMOTED
    case "CHECK_USER_MOLLIE_PROMOTED_REQUEST": {
      return state.merge({
        fetchingCheckUserMolliePromoted: true,
        errorCheckUserMolliePromoted: null,
      })
    }
    case "CHECK_USER_MOLLIE_PROMOTED_SUCCESS": {
      return state.merge({
        fetchingCheckUserMolliePromoted: false,
        errorCheckUserMolliePromoted: null,
      })
    }
    case "CHECK_USER_MOLLIE_PROMOTED_FAILURE": {
      return state.merge({
        fetchingCheckUserMolliePromoted: false,
        errorCheckUserMolliePromoted: action.error,
      })
    }

    // UPDATE MERCHANT CONTACT
    case "UPDATE_MERCHANT_CONTACT_REQUEST": {
      return state.merge({
        fetchingUpdateMerchantContact: true,
        errorUpdateMerchantContact: null,
      })
    }
    case "UPDATE_MERCHANT_CONTACT_SUCCESS": {
      return state.merge({
        fetchingUpdateMerchantContact: false,
        errorUpdateMerchantContact: null,
      })
    }
    case "UPDATE_MERCHANT_CONTACT_FAILURE": {
      return state.merge({
        fetchingUpdateMerchantContact: false,
        errorUpdateMerchantContact: action.error,
      })
    }

    // UPLOAD IBAN KYC
    case "UPLOAD_IBAN_KYC_REQUEST": {
      return state.merge({
        fetchingUploadIbanKyc: true,
        errorUploadIbanKyc: null,
      })
    }
    case "UPLOAD_IBAN_KYC_SUCCESS": {
      setStorage(STORAGE_KEY.KYC_DOCUMENT_SHOW, action.payload.data)
      return state.merge({
        fetchingUploadIbanKyc: false,
        errorUploadIbanKyc: null,
        kycDocumentShow: action.payload.data,
      })
    }
    case "UPLOAD_IBAN_KYC_FAILURE": {
      return state.merge({
        fetchingUploadIbanKyc: false,
        errorUploadIbanKyc: action.error,
      })
    }

    // CREATE MOLLIE
    case "CREATE_MOLLIE_BANK_REQUEST": {
      return state.merge({
        fetchingCreateMollieBank: true,
        errorCreateMollieBank: null,
      })
    }
    case "CREATE_MOLLIE_BANK_SUCCESS": {
      return state.merge({
        mollie: action.payload.data,
        fetchingCreateMollieBank: false,
        errorCreateMollieBank: null,
      })
    }
    case "CREATE_MOLLIE_BANK_FAILURE": {
      return state.merge({
        fetchingCreateMollieBank: false,
        errorCreateMollieBank: action.error,
      })
    }

    // UPDATE PASSWORD
    case "UPDATE_PASSWORD_REQUEST": {
      return state.merge({
        fetchingUpdatePassword: true,
        errorUpdatePassword: null,
      })
    }
    case "UPDATE_PASSWORD_SUCCESS": {
      return state.merge({
        fetchingUpdatePassword: false,
        errorUpdatePassword: null,
      })
    }
    case "UPDATE_PASSWORD_FAILURE": {
      return state.merge({
        fetchingUpdatePassword: false,
        errorUpdatePassword: action.error,
      })
    }

    // GET ME
    case "GET_ME_REQUEST": {
      return state.merge({
        fetchingGetMe: true,
        errorGetMe: null,
      })
    }
    case "GET_ME_SUCCESS": {
      const userData = Immutable.asMutable(state.userData)
      return state.merge({
        userData: { ...userData, user: action.payload.data },
        fetchingGetMe: false,
        errorGetMe: null,
      })
    }
    case "GET_ME_FAILURE": {
      return state.merge({
        fetchingGetMe: false,
        errorGetMe: action.error,
      })
    }

    // SAVE KYC
    case "SAVE_KYC_DOCUMENT": {
      return state.merge({
        kycDocument: action.document,
      })
    }
    default:
      return state
  }
}

export default MerchantReducer
