import React, { useEffect } from "react"
import "./header.css"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import images from "../theme/Images"

const routeNotShowIcon = ["/", "/confirm-email"]

const Header = () => {
  const [open, setOpen] = React.useState(false)
  const [isShowIcon, setShowIcon] = React.useState(false)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    routeNotShowIcon.includes(pathname) ? setShowIcon(false) : setShowIcon(true)
  }, [pathname, isShowIcon])

  const renderIcon = () => {
    return isShowIcon ? (
      <div className="contact">
        <h4 className="text-center text-white">Aide</h4>
        <div onClick={handleClickOpen} className="clickable">
          <img src={images.phone} alt="phone logo" />
        </div>
      </div>
    ) : (
      <div className="blank"></div>
    )
  }

  return (
    <div className="header-container">
      <div className="blank"></div>
      <div className="wrap-logo">
        <img src={images.logo} alt="app logo" className="img-logo" />
      </div>
      {renderIcon()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="text-center"
      >
        <DialogTitle id="alert-dialog-title">{t("callThisNumber")}</DialogTitle>
        <DialogContent>
          <DialogContentText>0179726555</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Header
